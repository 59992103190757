import React, { Component } from "react";
import mirador from "mirador";
import miradorDownloadPlugin from 'mirador-dl-plugin/es/miradorDownloadPlugin';
import MiradorDownloadDialog from 'mirador-dl-plugin/es/MiradorDownloadDialog';
import { miradorImageToolsPlugin } from 'mirador-image-tools';

class Mirador extends Component {
    componentDidMount() {
        const config = this.props.config;
        const plugins = [miradorImageToolsPlugin, miradorDownloadPlugin, MiradorDownloadDialog];
        mirador.viewer(config, plugins);
    }
    render() {
        return <div id="mirador" />;
    }
}

export default Mirador;